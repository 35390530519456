import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundVideo from '@activebrands/core-web/components/BackgroundVideo';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import Events from '@activebrands/core-web/libs/Events';
import { TrackingLists } from '@activebrands/core-web/libs/Tracking/constants';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import RichText, { RichTextPropType } from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArticleTag from 'components/ArticleTag';
import ArrowIcon from 'components/icons/ArrowIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Tag = styled('p', {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const EntryText = styled('div', {
    display: '-webkit-box',
    height: 'auto',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
});

const Info = styled('div', {
    padding: '16px 4px 0',
});

const ArticleCard = ({
    $style = {},
    categories = [],
    entryText,
    headingType = 'h3',
    imageSettings = {},
    media = {},
    maxRows = 2,
    objectID,
    position,
    publishedAt,
    searchMeta,
    title,
    cardHeadingLabel,
    to,
}) => {
    const [css] = useStyletron();
    const { width, sizes } = imageSettings;
    const published = publishedAt?.split('T')[0].replaceAll('-', '.').slice(2);
    const handleClick = () => {
        if (searchMeta) {
            Events.trigger(SearchEvents.CLICK, {
                ...searchMeta,
                objectIDs: [objectID],
                positions: [position + 1],
                eventName: `${TrackingLists.PRODUCT_GRID}: Search`,
                queryID: searchMeta.queryID,
                index: searchMeta.index,
            });
        }
    };

    return (
        <div className={css({ position: 'relative', ...$style })} onClick={handleClick}>
            {media && (
                <Link to={to}>
                    <div
                        className={css({
                            aspectRatio: 'var(--ratio-vertical-primary)',
                            marginBottom: ['12px', null, null, null, null, '8px'],
                        })}
                    >
                        {media.type === 'video' ? (
                            <BackgroundVideo $style={{ position: 'relative' }} src={media.src} />
                        ) : (
                            <Image sizes={sizes} src={{ url: media.src, width }} />
                        )}
                    </div>
                </Link>
            )}
            <Info>
                <div className={css({ display: 'flex', justifyContent: 'space-between' })}>
                    {categories?.length > 0 && (
                        <div className={css({ display: 'flex', flexWrap: 'wrap', gap: '8px', width: '75%' })}>
                            {categories?.map((category, index) => (
                                <ArticleTag key={`${category.name}_${index}`}>{category.name}</ArticleTag>
                            ))}
                        </div>
                    )}
                    {published && (
                        <Tag $style={{ ...textStyles['Misc/14_110_-0175'], whiteSpace: 'nowrap' }}>{published}</Tag>
                    )}
                </div>
                <Link to={to}>
                    <Heading
                        $style={{ margin: published ? '8px 0' : '16px 0 8px' }}
                        as={headingType}
                        fontKeys={['Misc/20_120_-025', null, null, null, 'Misc/24_120_-03']}
                    >
                        {cardHeadingLabel || title}
                    </Heading>
                </Link>

                {entryText && (
                    <EntryText $style={{ '-webkit-line-clamp': maxRows, ...textStyles['Secondary/14_130_-0175'] }}>
                        <RichText data={entryText} />
                    </EntryText>
                )}
                <Link to={to}>
                    <div
                        className={css({
                            alignItems: 'center',
                            display: ['flex', null, null, null, null, 'none'],
                            marginTop: '8px',
                        })}
                    >
                        <Paragraph $style={{ marginRight: '8px' }} fontKeys="Secondary/14_130_-0175">
                            {fm('Read more')}
                        </Paragraph>
                        <ArrowIcon width="12px" />
                    </div>
                </Link>
            </Info>
        </div>
    );
};

ArticleCard.propTypes = {
    $style: PropTypes.object,
    cardHeadingLabel: PropTypes.string,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
        })
    ),
    entryText: RichTextPropType,
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    imageSettings: PropTypes.object,
    maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    media: PropTypes.shape({
        src: PropTypes.string,
        type: PropTypes.string,
        alt: PropTypes.string,
        hostedBy: PropTypes.string,
        position: PropTypes.string,
        size: PropTypes.string,
        title: PropTypes.string,
    }),
    objectID: PropTypes.string,
    position: PropTypes.number,
    publishedAt: PropTypes.string,
    searchMeta: PropTypes.object,
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
};

export default ArticleCard;
