import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';

const StyledButton = styled('button', {
    whiteSpace: 'nowrap',
});

const mapSize = (prop, obj, key) => {
    if (obj && key) {
        return Array.isArray(key) ? key.map(s => (s ? obj[s]?.[prop] : null)) : obj[key]?.[prop];
    }

    return null;
};

const Button = ({
    $style = {},
    active = false,
    as,
    availableSizes = {},
    disabled = false,
    size,
    theme = {},
    type = 'button',
    ...rest
}) => {
    const { activeStyles, hoverStyles, disabledStyles, ...restTheme } = theme;
    const activeStyle = active ? activeStyles : {};
    const disabledStyle = disabled ? disabledStyles : {};
    const disabledStyleHover = disabledStyles?.hoverStyles ?? {};

    const styles = {
        cursor: 'pointer',
        ...restTheme,
        padding: $style.padding || mapSize('padding', availableSizes, size),
        height: $style.height || mapSize('height', availableSizes, size),
        ':hover:not(:disabled):not(.disabled)': active ? {} : hoverStyles,
        ':disabled:hover': disabledStyleHover,
        ':hover.disabled': disabledStyleHover,
        ...activeStyle,
        ...disabledStyle,
        ...$style,
    };

    return <StyledButton $as={as} $style={styles} disabled={disabled} type={!as ? type : null} {...rest} />;
};

Button.propTypes = {
    $style: PropTypes.object,
    active: PropTypes.bool,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    availableSizes: PropTypes.object,
    disabled: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    theme: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
};

export default Button;
